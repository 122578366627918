import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
        </div>
        <div className="content">
            <div className="inner">
                <h1>AMRIT</h1>
                <h3>MVC | Asp.Net | C# | SQL Server | Web Developer | JS </h3>
                <p>Working as Web Developer from last 5+ years. Strong troubleshooting and debugging skills developed over the years of web development work in various fields.<br />
                Software development using Asp.Net,  VB, C#, SQL server with the supporting technologies Java Script, JQuery & Angular Js.</p>
            </div>
        </div>
        <nav>
            <ul>
                {/* <li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>Intro</a></li> */}
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('work')}}>Work</a></li>
                {/* <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>About</a></li> */}
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
